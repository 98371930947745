import axios from "axios";

export const getMetafields = async (id: string) => {
  try {
    const { data } = await axios.post("/api/shopify/customer/getMetafield", {
      data: {
        id,
      },
    });
    if (data?.customer?.metafields?.edges?.length)
      return data?.customer?.metafields?.edges?.find(
        (item) => item?.node?.key === "birthday"
      )?.node?.value;
    return null;
  } catch (error) {
    return error;
  }
};
