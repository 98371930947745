import qs from "querystring";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { sumBy } from "lodash";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useQuery, useQueryClient } from "react-query";
import { useToggle } from "react-use";
import {
  formatShopifyIdForTracking,
  getProductClickOrigin,
  showToast,
  track,
} from "~lib";
import { getMetafields } from "~lib/getCustomerMetafields";
import { tokenInterceptor } from "~lib/handlers/tokenHandler/tokenHandler";
import { getShopifySdk } from "~lib/shopify/client";
import {
  CartFragmentFragment,
  CartLineItemInput,
  CartLineUpdateInput,
  CustomerActivateInput,
  CustomerQuery,
  PaymentProvidersQuery,
} from "~lib/shopify/sdk";

const LOCAL_STORAGE_CART_ID_KEY = "cartId";
const LOCAL_STORAGE_AUTH = "authTokens";
const COUNTRY_CODE = "countryCode";
const LOCAL_STORAGE_UTM_KEY = "utm";
const clientId = process.env.NEXT_PUBLIC_STOREFRONT_CLIENT_ID;

const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "gclid",
  "fbclid",
];

export const alertMessage = "Something went wrong";

const messages = {
  alertMessage: "Something went wrong",
  loginFail: "Invalid login credentials",
  passwordForgotSuccess: "An email has been sent",
  passwordResetSuccess: "Password was reset",
  activateSuccess: "Account activated!",
};
export interface ShopifyContextType {
  // Checkout
  cart: CartFragmentFragment;
  refetchCart: any;
  cartIsLoading: boolean;
  updatingCart: boolean;
  addItemToCart: (
    lineItems: CartLineItemInput[],
    cb?: (id?: string) => void,
    fromIframe?: boolean
  ) => Promise<void>;
  cartAttributesUpdate: (
    key: string,
    values: {
      recipe: string;
      ids: string[];
      quantity: number;
      discount?: number | string;
    }
  ) => Promise<void>;
  adjustLineItemQuantity: (
    lineItem: CartLineUpdateInput[],
    recipe?: string
  ) => void;
  removeLineItem: (
    id: string,
    cb?: () => void,
    recipe?: string
  ) => Promise<void>;
  countryCode: string;
  removeAllItems: () => void;
  setCartId: (id: string) => void;
  showCartOverlay: boolean;
  toggleCartOverlay: (nextValue?: boolean) => void;
  paymentProviders: PaymentProvidersQuery["shop"]["paymentSettings"];
  // Authentication
  getCustomerAccessToken: (code: string) => Promise<void>;
  logout: () => Promise<void>;
  // Customer
  customer?: CustomerQuery["customer"];
  refetchCustomer?: () => void;
  setIsCustomerLoading?: any;
  sendinblueCustomer?: any;
  setAccessToken?: any;
  refetchsendinblueCustomer?: any;
  customerIsLoading: boolean;
  createEmptyCart: (code?: string) => Promise<void>;
  customerActivate: (args: {
    id: string;
    input: CustomerActivateInput;
  }) => Promise<boolean>;
  quantity: number;
  customerBirthday: { value: string; id: string } | null;
  refetchCustomerBirthday: any;
  customerBirthdayLoading: boolean;
}

export const ShopifyContext = createContext<ShopifyContextType>(undefined);

export const ShopifyProvider: FC = ({ children }) => {
  const router = useRouter();
  const posthog = usePostHog();
  const queryClient = useQueryClient();
  const [cartId, setCartId] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [updatingCart, setUpdatingCart] = useState(false);
  const [paymentProviders, setProviders] = useState(null);
  const [hasToken, setHasToken] = useState(false);
  const [showCartOverlay, toggleCartOverlay] = useToggle(false);
  const shopifySdk = useMemo(
    () => getShopifySdk(router?.locale),
    [router?.locale]
  );
  const getCustomerAccessToken = async (code) => {
    try {
      const body = new URLSearchParams();
      body.append("grant_type", "authorization_code");
      body.append("client_id", clientId);
      body.append(
        "redirect_uri",
        `${window ? window.location.origin : ""}/account`
      );
      body.append("code", code);
      const codeVerifier = localStorage.getItem("code-verifier");
      body.append("code_verifier", codeVerifier);
      const headers = {
        "content-type": "application/x-www-form-urlencoded",
      };
      const response = await fetch(
        `https://shopify.com/10206689/auth/oauth/token`,
        {
          method: "POST",
          headers: headers,
          body,
        }
      );
      const data = await response.json();
      const dateExpired = new Date();
      dateExpired.setHours(dateExpired.getHours() + data.expires_in / 60 / 60);
      if (data.access_token) {
        localStorage.setItem(
          LOCAL_STORAGE_AUTH,
          JSON.stringify({ ...data, dateExpired })
        );
        setHasToken(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: customer,
    refetch: refetchCustomer,
    isLoading: isCustomerLoading,
    status,
  } = useQuery(
    ["customer", hasToken],
    async () => {
      try {
        const { data } = await tokenInterceptor(
          "/api/shopify/customer/getCustomer"
        );
        if (!data) logout();
        return data;
      } catch (e) {
        console.log(e);
        showToast("ERROR", "error");
      }
    },
    {
      enabled: router.isReady && hasToken,
      refetchOnWindowFocus: false,
    }
  );
  const customerIsLoading = isCustomerLoading;
  const cartIsDisabled = !cartId;
  const {
    data: cart,
    isLoading: cartIsLoading,
    refetch: refetchCart,
  } = useQuery(
    ["cart", cartId],
    async () =>
      shopifySdk
        .cart({ id: cartId })
        .then(({ cart }) => cart as CartFragmentFragment),
    {
      enabled: !cartIsDisabled,
    }
  );

  const setCartIdState = (id: string) => {
    localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, id);
    setCartId(id);
  };

  const getUtm = () => {
    let utm = {};

    try {
      const stored = JSON.parse(localStorage.getItem(LOCAL_STORAGE_UTM_KEY));

      return stored || utm;
    } catch (e) {}

    return utm;
  };
  const getCountryCode = () => {
    try {
      const stored = JSON.parse(localStorage.getItem(COUNTRY_CODE));

      return stored || "";
    } catch (e) {}

    return "";
  };
  // Store UTM params for use in create checkout
  useEffect(() => {
    const utm = getUtm();

    const params = qs.parse(window.location.search?.split("?")?.[1]);

    UTM_PARAMS.forEach((key) => {
      if (params?.[key]) {
        utm[key] = params[key];
      }
    });

    localStorage.setItem(LOCAL_STORAGE_UTM_KEY, JSON.stringify(utm));
  }, []);
  useEffect(() => {
    if (router.isReady) {
      const tokens = localStorage.getItem(LOCAL_STORAGE_AUTH);
      if (tokens) setHasToken(true);
    }
  }, [router.isReady]);
  useEffect(() => {
    if (!countryCode) {
      const code = getCountryCode();
      if (code) setCountryCode(code);
      else
        axios
          .get("https://ipapi.co/json/")
          .then(({ data }) => {
            setCountryCode(data.country_code);
            localStorage.setItem(COUNTRY_CODE, data.country_code);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }, [countryCode]);
  /**
   * If there is a checkoutId in localStorage, set it
   * If checkout is completed or invalid, unset the checkoutId
   * If there if an access token in localStorage, set it
   */
  useEffect(() => {
    const cartId = localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY);
    if (cartId) setCartId(cartId);
    shopifySdk
      .paymentProviders()
      .then((res) => setProviders(res.shop.paymentSettings))
      .catch((err) => console.log(err));
  }, []);
  /**
   * If checkout is completed or invalid, unset the checkoutId
   */
  useEffect(() => {
    if (cart === null) {
      setCartId("");
    }
  }, [cart]);
  const customerTracked = useRef<boolean | "logged_in" | "logged_out">(false);
  // Track customer data
  useEffect(() => {
    const url = router.asPath.includes("?")
      ? router.asPath.slice(0, router.asPath.indexOf("?"))
      : router.asPath;
    if (prevUrl === url) return;
    if (!customerIsLoading && !cartIsLoading) {
      if (customer) {
        posthog.identify(customer?.emailAddress?.emailAddress);
        if (customerTracked.current === "logged_out") {
          // on login
          customerTracked.current = "logged_in";
          const address = customer?.addresses?.edges?.length;
          track({
            event: "dl_login",
            user_properties: {
              visitor_type: "logged_in",
              customer_first_name: String(customer.firstName),
              customer_last_name: String(customer.lastName),
              customer_phone: String(customer.phoneNumber?.phoneNumber),
              customer_city: address
                ? customer.addresses.edges[0].node.city
                : "-",
              customer_zip: address
                ? customer.addresses.edges[0].node.zip
                : "-",
              customer_address_1: address
                ? customer.addresses.edges[0].node.address1
                : "-",
              customer_address_2: address
                ? customer.addresses.edges[0].node.address2
                : "-",
              customer_country: address
                ? customer.addresses.edges[0].node.country
                : "-",
              customer_country_code: address
                ? customer.addresses.edges[0].node.countryCodeV2
                : "-",
              customer_province: address
                ? String(customer.addresses.edges[0].node.province)
                : "-",
              customer_province_code: "-",
              customer_id: customer.id,
              customer_email: customer?.emailAddress?.emailAddress,
              customer_order_count: `${customer.allOrders?.edges?.length || 0}`,
              customer_total_spent: `${customer.allOrders?.edges?.reduce(
                (total, current) => {
                  total += current?.node?.totalPriceV2?.amount || 0;
                  return total;
                },
                0
              )}`,
              customer_tags: customer.tags?.join(","),
              user_consent: customer.acceptsMarketing
                ? "yes"
                : "no_interaction",
            },
          });
        } else {
          const address = customer?.addresses?.edges?.length;
          customerTracked.current = "logged_in";
          (window as any)?.TriplePixel("Contact", {
            email: customer.email,
          });
          (window as any)?.TriplePixel("Contact", {
            phone: String(customer.phoneNumber?.phoneNumber),
          });
          track({
            event: "dl_user_data",
            cart_total: String(cart?.cost.totalAmount?.amount || 0),
            ecommerce: {
              currencyCode: "EUR",
              cart_contents: {
                products: cart?.lines?.edges?.length
                  ? cart.lines.edges.map(({ node }) => ({
                      id: node.id,
                      name: node.merchandise.title,
                      brand: "",
                      category: "",
                      variant: node.merchandise.title,
                      price: node.merchandise.priceV2.amount,
                      quantity: String(node.merchandise.quantityAvailable),
                      list: "",
                      product_id: node.id,
                      variant_id: node.merchandise.id,
                      image: node?.merchandise?.image?.originalSrc,
                    }))
                  : [],
              },
            },
            user_properties: {
              visitor_type: "logged_in",
              customer_first_name: String(customer.firstName),
              customer_last_name: String(customer.lastName),
              customer_phone: String(customer.phoneNumber?.phoneNumber),
              customer_city: address
                ? customer.addresses.edges[0].node.city
                : "-",
              customer_zip: address
                ? customer.addresses.edges[0].node.zip
                : "-",
              customer_address_1: address
                ? customer.addresses.edges[0].node.address1
                : "-",
              customer_address_2: address
                ? customer.addresses.edges[0].node.address2
                : "-",
              customer_country: address
                ? customer.addresses.edges[0].node.country
                : "-",
              customer_country_code: address
                ? customer.addresses.edges[0].node.countryCodeV2
                : "-",
              customer_province: address
                ? String(customer.addresses.edges[0].node.province)
                : "-",
              customer_province_code: "-",
              customer_id: customer.id,
              customer_email: customer?.emailAddress?.emailAddress,
              customer_order_count: `${customer.allOrders?.edges?.length || 0}`,
              customer_total_spent: `${customer.allOrders?.edges?.reduce(
                (total, current) => {
                  total += current?.node?.totalPriceV2?.amount || 0;
                  return total;
                },
                0
              )}`,
              customer_tags: customer.tags?.join(","),
              user_consent: customer.acceptsMarketing
                ? "yes"
                : "no_interaction",
            },
          });
        }
      } else {
        customerTracked.current = "logged_out";
        track({
          event: "dl_user_data",
          cart_total: String(cart?.cost.totalAmount?.amount || 0),
          ecommerce: {
            currencyCode: "EUR",
            cart_contents: {
              products: cart?.lines?.edges?.length
                ? cart.lines.edges.map(({ node }) => ({
                    id: node.id,
                    name: node.merchandise.title,
                    brand: "",
                    category: "",
                    variant: node.merchandise.title,
                    price: node.merchandise.priceV2.amount,
                    quantity: String(node.merchandise.quantityAvailable),
                    list: "",
                    product_id: node.id,
                    variant_id: node?.merchandise?.id,
                    image: node?.merchandise?.image?.originalSrc,
                  }))
                : [],
            },
          },
          user_properties: {
            visitor_type: "guest",
            user_consent: "",
          },
        });
      }
      setPrevUrl(
        router.asPath.includes("?")
          ? router.asPath.slice(0, router.asPath.indexOf("?"))
          : router.asPath
      );
    }
  }, [cart, cartIsLoading, customer, router]);
  // *************************
  // Checkout ****************
  // *************************
  useEffect(() => {
    if (cart && customer && !cart?.buyerIdentity.customer) {
      shopifySdk.cartBuyerIdentityUpdate({
        cartId: cart.id,
        buyerIdentity: {
          email: customer?.emailAddress?.emailAddress,
          phone: customer?.phoneNumber?.phoneNumber,
          countryCode: customer?.defaultAddress?.countryCodeV2,
          deliveryAddressPreferences: [
            {
              deliveryAddress: {
                address1: customer.defaultAddress?.address1,
                address2: customer.defaultAddress?.address2,
                city: customer.defaultAddress?.city,
                company: customer.defaultAddress?.company,
                firstName: customer.defaultAddress?.firstName,
                lastName: customer.defaultAddress?.lastName,
                province: customer.defaultAddress?.province,
                zip: customer.defaultAddress?.zip,
                country: customer.defaultAddress?.country,
              },
            },
          ],
        },
      });
    }
  }, [customer, cart]);
  const trackAddToCart = (
    lines: CartLineItemInput[],
    cartData: CartFragmentFragment
  ) => {
    lines.forEach(({ merchandiseId, quantity }) => {
      const item = cartData?.lines?.edges?.find(
        ({ node }) => merchandiseId === node.merchandise.id
      );

      if (item?.node?.merchandise) {
        //@ts-ignore
        TriplePixel("AddToCart", {
          item: item.node.merchandise.id,
          q: quantity,
        });
        track({
          event: "dl_add_to_cart",
          ecommerce: {
            currencyCode: item?.node?.merchandise?.priceV2?.currencyCode,
            add: {
              actionField: {
                list: getProductClickOrigin(
                  formatShopifyIdForTracking(item.node.merchandise.product.id)
                ),
              },
              products: [
                {
                  name: item?.node?.merchandise.product.title || "",
                  id: item.node.merchandise.sku || "",
                  product_id: formatShopifyIdForTracking(
                    item.node.merchandise.product.id
                  ),
                  variant_id:
                    formatShopifyIdForTracking(item.node.merchandise.id) || "",
                  image: item?.node?.merchandise?.image?.originalSrc || "",
                  price: item?.node?.merchandise?.priceV2?.amount,
                  brand: item.node.merchandise.product.vendor || "",
                  variant: item.node.merchandise.title || "",
                  category: item.node.merchandise.product.productType,
                  quantity: `${quantity}`,
                  list: getProductClickOrigin(
                    formatShopifyIdForTracking(item.node.merchandise.product.id)
                  ),
                },
              ],
            },
          },
        });
      }
    });
  };
  const createEmptyCart = async (code: string) => {
    const utm = getUtm();
    const { cartCreate } = await getShopifySdk(router?.locale, utm).cartCreate({
      input: {},
    });
    setCartIdState(cartCreate.cart.id);
    if (code)
      await shopifySdk.cartDiscountCodesUpdate({
        cartId: cartCreate.cart.id,
        discountCodes: [code],
      });
  };
  const addItemToCart = async (
    lines: CartLineItemInput[],
    cb,
    fromIframe = false
  ) => {
    let id = "";
    try {
      setUpdatingCart(true);
      if (!cartId) {
        const utm = getUtm();

        const customAttributes = [];
        if (fromIframe)
          customAttributes.push({
            key: "fromIframe",
            value: String(fromIframe),
          });
        const cartInput = { lines };
        const { cartCreate } = await getShopifySdk(
          router?.locale,
          utm
        ).cartCreate({ input: cartInput });
        setCartIdState(cartCreate.cart.id);
        if (cartCreate.userErrors.length)
          showToast(cartCreate.userErrors[0].message, "error");
        else {
          queryClient.setQueryData(["cart", cartId], cartCreate.cart);
          trackAddToCart(lines, cartCreate.cart);
          id = cartCreate.cart.id;
        }
      } else {
        const { cartLinesAdd } = await shopifySdk.cartLinesAdd({
          cartId,
          lines: lines,
        });
        if (cartLinesAdd.userErrors.length)
          showToast(cartLinesAdd.userErrors[0].message, "error");
        else {
          queryClient.setQueryData(["cart", cartId], cartLinesAdd.cart);
          trackAddToCart(lines, cartLinesAdd.cart);
        }
      }

      // toggleCartOverlay(true);
      setUpdatingCart(false);
    } catch (err) {
      console.warn(err);
      setUpdatingCart(false);
      showToast("Error", "error");
      refetchCart();
    } finally {
      if (cb) cb(cartId || id);
      setUpdatingCart(false);
    }
  };
  const cartAttributesUpdate = async (
    key: string,
    values: {
      recipe: string;
      ids: string[];
      quantity: number;
    }
  ) => {
    try {
      setUpdatingCart(true);
      let localCartId = localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY);
      const recipeAttribute = cart?.attributes.find(
        (item) => item.key === "recipe"
      );
      const oldAttributes =
        recipeAttribute && JSON.parse(recipeAttribute.value);
      const { cartAttributesUpdate } = await shopifySdk.cartAttributesUpdate({
        cartId: cartId || localCartId,
        attributes: oldAttributes
          ? [
              {
                key,
                value: JSON.stringify([
                  ...oldAttributes.filter(
                    (item) => item.recipe !== values.recipe
                  ),
                  values,
                ]),
              },
            ]
          : [{ key, value: JSON.stringify([values]) }],
      });

      if (cartAttributesUpdate.userErrors.length) showToast("Error", "error");
      else {
        queryClient.setQueryData(
          ["cart", cartId || localCartId],
          cartAttributesUpdate.cart
        );
      }
    } catch (err) {
      showToast("Error", "error");
      refetchCart();
    } finally {
      setUpdatingCart(false);
    }
  };
  const quantity = useMemo(
    () =>
      sumBy(cart?.lines?.edges, function (item) {
        return item?.node?.quantity;
      }),
    [cart]
  );
  const adjustLineItemQuantity = async (
    lines: CartLineUpdateInput[],
    recipe?: string
  ) => {
    try {
      setUpdatingCart(true);

      if (!lines[0].quantity) {
        await removeLineItem(lines[0].id);
        return;
      }
      const { cartLinesUpdate } = await shopifySdk.cartLinesUpdate({
        cartId,
        lines,
      });
      if (cartLinesUpdate.userErrors.length)
        showToast(cartLinesUpdate.userErrors[0].message, "error");
      else {
        queryClient.setQueryData(["cart", cartId], cartLinesUpdate.cart);
        trackAddToCart(
          [{ quantity: lines[0].quantity, merchandiseId: lines[0].id }],
          cartLinesUpdate.cart
        );
      }
      if (recipe) {
        const recipeAttribute = cart?.attributes.find(
          (item) => item.key === "recipe"
        );
        const oldAttributes =
          recipeAttribute && JSON.parse(recipeAttribute.value);
        if (oldAttributes) {
          const { cartAttributesUpdate } =
            await shopifySdk.cartAttributesUpdate({
              cartId: cartId,
              attributes: [
                {
                  key: "recipe",
                  value: JSON.stringify(
                    oldAttributes.map((item) => {
                      if (item.recipe === recipe)
                        return { ...item, quantity: lines[0].quantity };
                      else return item;
                    })
                  ),
                },
              ],
            });
          if (cartAttributesUpdate.userErrors.length)
            showToast("Error", "error");
          else {
            queryClient.setQueryData(
              ["cart", cartId],
              cartAttributesUpdate.cart
            );
          }
        }
      }
    } catch (err) {
      showToast("Error", "error");
      refetchCart();
    } finally {
      setUpdatingCart(false);
    }
  };
  const removeLineItem = async (
    id: string,
    cb?: () => void,
    recipe?: string
  ) => {
    setUpdatingCart(true);
    try {
      const item = cart.lines.edges?.find(({ node }) => node.id === id);
      if (item) {
        track({
          event: "dl_remove_from_cart",
          ecommerce: {
            currencyCode: item?.node?.merchandise?.priceV2?.currencyCode,
            remove: {
              actionField: {
                list: getProductClickOrigin(
                  formatShopifyIdForTracking(
                    item.node?.merchandise?.product?.id
                  )
                ),
              },
              products: [
                {
                  name: item?.node?.merchandise?.product?.title || "",
                  id: item.node.merchandise.sku || "",
                  product_id: formatShopifyIdForTracking(
                    item.node?.merchandise?.product?.id
                  ),
                  variant_id:
                    formatShopifyIdForTracking(item.node.merchandise.id) || "",
                  image: item?.node?.merchandise?.image?.originalSrc || "",
                  price: item?.node?.merchandise?.priceV2?.amount,
                  brand: item.node?.merchandise?.product?.vendor || "",
                  variant: item.node.merchandise.title || "",
                  category: item.node?.merchandise?.product?.productType,
                  quantity: String(item.node.quantity),
                  list: getProductClickOrigin(
                    formatShopifyIdForTracking(
                      item.node?.merchandise?.product?.id
                    )
                  ),
                },
              ],
            },
          },
        });
      }

      const { cartLinesRemove } = await shopifySdk.cartLinesRemove({
        cartId,
        lineIds: [id],
      });
      if (cartLinesRemove.userErrors.length) showToast("Error", "error");
      else {
        queryClient.setQueryData(["cart", cartId], cartLinesRemove.cart);
      }
      if (recipe) {
        const attributes = JSON.parse(cart.attributes[0].value);
        const { cartAttributesUpdate } = await shopifySdk.cartAttributesUpdate({
          cartId: cartId,
          attributes: [
            {
              key: "recipe",
              value: JSON.stringify(
                attributes.filter((item) => item.recipe !== recipe)
              ),
            },
          ],
        });
        if (cartAttributesUpdate.userErrors.length) showToast("Error", "error");
        else {
          queryClient.setQueryData(["cart", cartId], cartAttributesUpdate.cart);
        }
      }
      setUpdatingCart(false);
      refetchCart();
      if (cb) cb();
    } catch {
      showToast("Error", "error");
      refetchCart();
    }
  };
  const removeAllItems = async () => {
    setUpdatingCart(true);
    const items = cart.lines.edges.map(({ node }) => node.id);
    const { cartLinesRemove } = await shopifySdk.cartLinesRemove({
      cartId,
      lineIds: items,
    });
    queryClient.setQueryData(["cart", cartId], cartLinesRemove.cart);
    setUpdatingCart(false);
    const { cartAttributesUpdate } = await shopifySdk.cartAttributesUpdate({
      cartId: cartId,
      attributes: [],
    });
    queryClient.setQueryData(["cart", cartId], cartAttributesUpdate.cart);
  };
  // *************************
  // Authentication **********
  // *************************

  const logout = async () => {
    const tokens = localStorage.getItem(LOCAL_STORAGE_AUTH);
    const { id_token } = JSON.parse(tokens);
    posthog.reset();
    if (id_token)
      window.location.href = `https://shopify.com/10206689/auth/logout?id_token_hint=${id_token}`;
    setCartIdState("");
    localStorage.removeItem(LOCAL_STORAGE_AUTH);
    setHasToken(false);
    document.cookie = `refresh=; Max-Age=0`;
  };

  // *************************
  // Customer ****************
  // **********************

  const getKlaviyoContact = async () => {
    try {
      const { data } = await axios.post(`/api/klaviyo/getCustomer`, {
        email: customer?.emailAddress?.emailAddress,
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: sendinblueCustomer, refetch: refetchsendinblueCustomer } =
    useQuery(["sendinblueCustomer"], () => getKlaviyoContact());
  useEffect(() => {
    refetchsendinblueCustomer();
  }, [customer]);

  const customerActivate = async ({
    id,
    input,
  }: {
    id: string;
    input: CustomerActivateInput;
  }) => {
    return shopifySdk
      .customerActivate({ id, input })
      .then((res) => {
        if (res.customerActivate.customerUserErrors?.length) {
          throw res.customerActivate.customerUserErrors;
        }

        refetchCustomer();

        showToast(messages.activateSuccess, "success");
        router.push("/account");

        return true;
      })
      .catch((err) => {
        console.error(err);

        showToast(messages.alertMessage || alertMessage, "error");
        return false;
      });
  };
  const {
    data: customerBirthday,
    refetch: refetchCustomerBirthday,
    isLoading: customerBirthdayLoading,
  } = useQuery(["date"], () => getMetafields(customer?.id), {
    enabled: !!customer?.id,
  });
  return (
    <ShopifyContext.Provider
      value={{
        // Checkout
        addItemToCart,
        removeLineItem,
        cartAttributesUpdate,
        removeAllItems,
        adjustLineItemQuantity,
        updatingCart,
        cart,
        cartIsLoading,
        refetchCart,
        showCartOverlay,
        toggleCartOverlay,
        paymentProviders,
        quantity,
        // setAccessToken,
        // Authentication
        logout,
        // Customer
        refetchCustomer,
        countryCode,
        getCustomerAccessToken,
        customer,
        setCartId,
        sendinblueCustomer,
        refetchsendinblueCustomer,
        customerIsLoading,
        createEmptyCart,
        customerActivate,
        //CustomerBirthday
        customerBirthday,
        refetchCustomerBirthday,
        customerBirthdayLoading,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = () => {
  const ctx = useContext(ShopifyContext);

  if (!ctx) {
    throw new Error(
      "Shopify context must be used within a shopify context provider"
    );
  }

  return ctx;
};
