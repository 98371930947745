import axios from "axios";
const clientId = process.env.NEXT_PUBLIC_STOREFRONT_CLIENT_ID;

const logout = () => {
  const tokens = localStorage.getItem("authTokens");
  const { id_token } = JSON.parse(tokens);
  if (id_token)
    window.location.href = `https://shopify.com/10206689/auth/logout?id_token_hint=${id_token}`;
  localStorage.removeItem("authTokens");
  document.cookie = `refresh=; Max-Age=0`;
};

const loadRefreshToken = async () => {
  try {
    const body = new URLSearchParams();
    const { refresh_token } = JSON.parse(localStorage.getItem("authTokens"));
    body.append("grant_type", "refresh_token");
    body.append("client_id", clientId);
    body.append("refresh_token", String(refresh_token));
    const headers = {
      "content-type": "application/x-www-form-urlencoded",
    };
    const response = await fetch(
      `https://shopify.com/10206689/auth/oauth/token`,
      {
        method: "POST",
        headers: headers,
        body,
      }
    );
    const data = await response.json();
    if (data.error) throw new Error("error");
    const dateExpired = new Date();
    dateExpired.setHours(dateExpired.getHours() + data.expires_in / 60 / 60);
    if (data.access_token) {
      localStorage.setItem(
        "authTokens",
        JSON.stringify({ ...data, dateExpired })
      );
    }
  } catch (e) {
    console.log(e);
    logout();
  }
};

export const tokenInterceptor = async (
  url,
  method = "GET",
  body = null,
  id = ""
) => {
  try {
    let token = await (localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null);
    const isExpired = new Date() > new Date(token.dateExpired);
    if (isExpired) {
      await loadRefreshToken();
      token = await JSON.parse(localStorage.getItem("authTokens"));
    }
    if (token) {
      if (method === "POST")
        return await axios.post(
          `${url}?token=${token.access_token}&id=${id}`,
          body
        );
      return await axios.get(`${url}?token=${token.access_token}&id=${id}`);
    }
  } catch (e) {
    console.log(e);
    logout();
  }
};
